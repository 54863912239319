* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "PP Editorial New";
  color: #cac3b5;
}

body {
  width: 100%;
  height: 100vh;
  background: #071104;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: none;
}

@media (max-width: 900px) {
  .h1 {
    font-size: 2em;
  }
  .p {
    font-size: 8px;
  }
}
