  .contact-page{
    height: 40em;
    
  }
  .contact-col {
    width: 50%;
    padding: 1em 3em;
  }
  
  .contact-section h3 {
    text-transform: uppercase;
    color: #747474;
    font-weight: 400;
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .contact-section span {
    text-transform: uppercase;
  }
  
  .contact-details p {
    font-size: 40px;
    line-height: 60px;
    padding-top: 0.8em;
  }
  .contact-social-media {
    height: 80px;
    border: none;
  }
  .contact-item {
    padding: 4em 0 0 0;
  }
  
  .contact-info {
    display: flex;
  }
  .contact-email{
    border: none;
  }
  .contact-address {
    padding-left: 4em;
    border: none;
  }
  
  input {
    background: none;
    outline: none;
    border: none;
    border-bottom: 1px solid #747474;
    font-size: 24px;
    padding: 0.4em;
    width: 100%;
    color: #b4b4b4;
    margin-right: 0.4em;
  }
  
  .contact-row {
    display: flex;
    margin: 2em 0 !important;
  }
  
  textarea#message {
    width: 100%;
    background: none;
    outline: none;
    border: none;
    border-bottom: 1px solid #747474;
    font-size: 24px;
    padding: 0.4em;
    width: 100%;
    color:#3d3d3d;
    font-family: "Neue Montreal";
    resize: vertical;
  }

  .send-button{
    display: inline-block;
    border: 2px solid #58513a;
    color: #ffffff;
    text-decoration: none;
    background-color: transparent;
    padding: 10px 20px;
    transition: background-color 0.3s;
  }
  .send-button:hover{
    background-color: #b3a182;
    color:rgb(255, 255, 255);
  }
  

  @media (min-width: 900px) {
    body {
      height: 100%;
    }
  
    .contact-section {
      width: 100%;
      display: flex;
    }
  }
  
  