
.about-page{
  height: 40em;
}
.link{
    cursor: none;
    margin-right: 3em;
}

.items {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
  }

  .item {
    position: relative;
    width: 350px;
    height: 400px;
    overflow: hidden;
    cursor: none;
  }
  
  .item-img {
    width: 100%;
    height: 100%;
    border: none;
  }
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: grayscale(0) sepia(0) brightness(1);
    transition: all 1.5s cubic-bezier(0.075, 0.82, 0.165, 1);
    border-radius: 20%;
    border: none;
  }
  
  .item-copy {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 15px 20px;
  }
  
  .item-copy > div {
    flex: 1;
    display: flex;
    align-items: center;
  }
  
  #item-1 .item-copy-2 {
    justify-content: flex-end;
  }
  
  .shape {
    width: 175px;
    height: 175px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(20px);
    color: #fff;
    font-size: 12px;
    padding: 10px;
    opacity: 0;
    transform: scale(0.75);
    transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
    border: none;
  }
  
  #item-1 .shape {
    clip-path: polygon(
      0% 0px,
      0px 0%,
      calc(100% - 50px) 0px,
      100% 50px,
      100% 100%,
      calc(100% - 0px) 100%,
      50px 100%,
      0px calc(100% - 50px)
    );
  }
  
  .shape {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .shape #number {
    font-family: "Orbitron";
    font-size: 50px;
    font-weight: 400;
  }
  
  .text-right {
    text-align: right;
  }
  
  .item:hover img {
    transform: scale(1.5);
    filter: grayscale(0.5) sepia(0.75) brightness(0.75);
  }
  
  .item:hover .shape {
    opacity: 1;
    transform: scale(1);
  }
  .description{
    width: 20em;
    font-family: "Neue Montreal";
    font-size: 1.2em;
  }
  .white-space3{
    width: 100%;
    height: 1em;
  }
  .reveal2{
        animation-name: revealFromBottom;
        animation-duration: 3.2s;
        animation-timing-function: ease-in-out;
        animation-fill-mode: forwards;
        opacity: 0;
        transform: translateY(100%);
  }
  @keyframes revealFromBottom {
    0% {
      opacity: 0;
      transform: translateY(100%);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
.work{
  margin-left: 3em;
}
.description-work{
    margin-left: -2em;
    transition: transform 0.3s ease-in-out;
    padding: 15px;
}
.description-work:hover{
    background-color: #f0dfc2;
    transform: scale(1.05);
    border-radius: 1em;
}
.description-work:hover span{
  color:black;
}
.description-work:hover h3{
  color:black;
}
.img-text{
    color: white;
}