.custom-cursor {
    width: 20px;
    height: 20px;
    background-color: #a6a6a6;
    border-radius: 50%;
    position: fixed;
    pointer-events: none;
    transform: translate(-50%, -50%);
    transition: transform 0.15s ease;
    z-index: 9999;
}

  