.nav {
    position: fixed;
    z-index: 2;
  }
  
  .navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1em;
  }
  
  .logo {
    text-decoration: none;
    font-family: "Monument Extended";
    font-weight: bolder;
    text-transform: uppercase;
  }
  
  .menu-toggle {
    cursor: pointer;
  }
  
  .nav-overlay {
    position: fixed;
    background: #041302;
    z-index: -2;
    top: -100%;
    left: 0;
    width: 100%;
    height: 85vh;
    overflow: hidden;
    padding: 4em;
    transition: all 2s cubic-bezier(0.16, 1, 0.3, 1);
  }
  
  .hamBox {
    position: relative;
    cursor: pointer;
    border-radius: 0%;
    transition: 0.3s ease;
    padding: 2.4em;
    border-radius: 60%;
    background-color: rgb(242, 229, 198);
  }
  
  .hamBoxOpen {
    background: rgb(205, 196, 174);
  }
  
  .lineTop,
  .lineBottom {
    margin: 0 auto;
    position: absolute;
    display: block;
    width: 24px;
    height: 2px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.5);
    left: 0;
    right: 0;
    transform: rotate(0deg);
    transition: all 0.4s;
  }
  
  .lineTop {
    top: 2.2em;
    background: #000000;
  }
  
  .lineBottom {
    bottom: 2.2em;
    background: black;
  }
  
  .lineTop.spin {
    top: 2.35em;
    transform: rotate(135deg);
    background: #000000;
  }
  
  .lineBottom.spin {
    bottom: 2.35em;
    transform: rotate(225deg);
    background: #000000;
  }
  
  ul.nav-links {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  ul.nav-links li {
    list-style: none;
    position: relative;
  }
  
  ul.nav-links li a {
    text-decoration: none;
    color: #cac3b5;
    font-family: "Rosseta";
    font-size: 6em;
    position: relative;
    top: 0;
    transition: all 2s cubic-bezier(0.16, 1, 0.3, 1);
  }
  
  .nav-item-wrapper:after {
    content: "";
    position: absolute;
    top: 120px;
    left: -10%;
    width: 120%;
    height: 100%;
    background: #041302;
    margin: 0 auto;
  }
  
  .nav-footer {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    padding: 2em;
    display: flex;
    justify-content: space-between;
  }
  
  .nav-social-media ul {
    display: flex;
  }
  
  .nav-social-media ul li {
    list-style: none;
  }
  
  .nav-social-media ul li a {
    position: relative;
    bottom: -20px;
    text-decoration: none;
    font-family: sans-serif;
    color: #fff;
    text-transform: uppercase;
    padding-left: 2em;
    opacity: 0;
    transition: all 2s cubic-bezier(0.16, 1, 0.3, 1);
  }
  
  .location {
    position: relative;
    bottom: -20px;
    opacity: 0;
    transition: all 2s cubic-bezier(0.16, 1, 0.3, 1);
    font-family: sans-serif;
    text-transform: uppercase;
  }
  
  @media (max-width: 900px) {
    ul.nav-links li a {
      font-size: 5em;
    }
  
    .location {
      display: none;
    }
  
    .nav-footer {
      justify-content: space-around;
    }
  
    .nav-social-media ul li a {
      padding: 0 1em;
    }
  }